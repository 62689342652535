<template>
    <div class="card card-height">
        <div class="table-responsive">
            <table class="table ipd-billing">
                <thead>
                    <tr>
                        <th>Photo</th>
                        <th class="patient-details">Patient Details</th>
                        <th>Consultant Name</th>
                        <th>Ward/Bed/Cabin</th>
                        <th>Admission No</th>
                        <th>Medicine Order Ref</th>
                        <th class="action-th">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in tableItems" :key="i">

                        <td> <img :src=item.photo alt="avatar" class="profile-pic"></td>

                        <td> 
                            <div class="col">
                                <strong>
                                    <h4>{{ item.patientName }} ({{ item.age }} years, {{ item.gender }}) </h4>
                                </strong>
                                {{ item.mobile }} | {{ item.id }}
                            </div>
                        </td>

                        <td>
                            {{ item.consultant_name }}
                        </td>

                        <td>
                            {{ item.word_no }}
                        </td>

                        <td>
                            {{ item.admission_no }}
                        </td>

                        <td>
                            {{ item.medicine_order_ref }}
                        </td>

                        <td>

                            <div class="action">

                                <button class="btn btn-secondary btn-sm print-btn">
                                    <i class="fas fa-print"></i>
                                </button>

                                <button @click="goToCreate" class="btn btn-primary btn-sm">
                                    <i class="fas fa-plus"></i>
                                    Bill
                                </button>
                            </div>

                        </td>
                    </tr>
                </tbody>
            </table>
        <p v-if="!tableItems.length" class="text-center mt-5">No data</p>
    </div>
</div>
</template>

<script setup>
    import { useRoute, useRouter } from "vue-router";

    const $route = useRoute();
    const $router = useRouter();

    const props = defineProps({
        tableItems: {
            type: Array,
        }
    })
    const goToCreate = () => {
        $router.push({name: 'invoice-investigation',
            params: {
                companyId: $route.params.companyId,
                moduleId: $route.params.moduleId,
                menuId: $route.params.menuId,
                pageId: $route.params.pageId
            },
            query: {}

        })
    }
</script>

<style scoped>
.ipd-billing > :not(caption) > * > * {
    padding: 0.5rem 0.2rem;
}
.card-height{
    min-height: 200px
}
.patient-details{
    width: 25%;
}
.print-btn{
    margin-right: 5px;
}
.action-th{
    width: 15%;
    text-align: center;
}
.action{
    text-align: center;
}

.profile-pic{
    width: 65px;
}
</style>